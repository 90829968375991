import { Maybe } from '@/common/types';
import { useLoading } from '@/context/LoadingContext';
import {
  type NewWorkOrderInput,
  type UpdateWorkOrderInput,
  type WorkOrderPriority,
  type WorkOrderStatus,
} from '@/graphql/types';
import { ICreateCheckListInput, IUpdateCheckListInput } from '@/modules/checkList';
import { useToast } from '@/utils/atoms/toast';
import useTranslation from '@/utils/i18n/useTranslation';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import {
  useAddWorkOrderMutation,
  useUpdateWorkOrderMutation,
} from '../graphql/workOrders.generated';
import type {
  IWorkOrder,
  IWorkOrderAssigneeInput,
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFileValue,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
  IWorkOrderPart,
  IWorkOrderScheduleDueDateInput,
  IWorkOrderScheduleMonthlyDateInput,
  IWorkOrderScheduleMonthlyDayOfWeekDateInput,
  IWorkOrderScheduleWeeklyDateInput,
  IWorkOrderStoppage,
} from '../types/workOrder';

export type WorkOrderFormBaseDataType = {
  title?: string | undefined;
  assetId?: number | undefined;
  productId?: number | undefined;
  description?: string | undefined;
  dueDate?: string | null;
  status?: WorkOrderStatus;
  priority?: WorkOrderPriority;
  stoppage?: IWorkOrderStoppage;
  stoppageReasonId?: number | null;
  parts: IWorkOrderPart[];
  customFieldTextValues: IWorkOrderCustomFieldTextValueInput[];
  customFieldIntValues: IWorkOrderCustomFieldIntValueInput[];
  customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[];
  customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[];
  customFieldDateValues: IWorkOrderCustomFieldDateValueInput[];
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[];
  customFieldUserValues: IWorkOrderCustomFieldUserValueInput[];
  customFieldFileValues: IWorkOrderCustomFieldFileValue[];
  assignees: IWorkOrderAssigneeInput[];
  groupIds?: number[];
};

// 名前変える
export type WorkOrderFormDataType = WorkOrderFormBaseDataType & {
  checkLists?: ICreateCheckListInput[];
  templateId: number;
  scheduleDueDateInput?: IWorkOrderScheduleDueDateInput;
  scheduleWeeklyDateInput?: IWorkOrderScheduleWeeklyDateInput;
  scheduleMonthlyDateInput?: IWorkOrderScheduleMonthlyDateInput;
  scheduleMonthlyDayOFWeekDateInput?: IWorkOrderScheduleMonthlyDayOfWeekDateInput;
};

export type UpdateWorkOrderFormDataType = WorkOrderFormBaseDataType & {
  id: number;
  checkLists?: IUpdateCheckListInput[];
};

type ReturnType = {
  addWorkOrder: (payload: WorkOrderFormDataType) => Promise<Maybe<IWorkOrder>>;
  updateWorkOrder: (payload: UpdateWorkOrderFormDataType) => Promise<Maybe<IWorkOrder>>;
};

export const useAddWorkOrder = (): ReturnType => {
  const { toast } = useToast();

  const [addWorkOrderMutation] = useAddWorkOrderMutation();
  const [updateWorkOrderMutation] = useUpdateWorkOrderMutation();

  const { t_toasts } = useTranslation();
  const { loading } = useLoading();

  const addWorkOrder = useCallback(
    async (payload: WorkOrderFormDataType) => {
      loading(true);
      try {
        const { data } = await addWorkOrderMutation({
          variables: { newWorkOrderInput: payload as NewWorkOrderInput },
        });
        if (!data) throw new Error('Data Error');
        const workOrder = data.addWorkOrder as IWorkOrder;
        toast({
          title: t_toasts('success.task.task-added'),
          status: 'success',
          isAlwaysShow: true,
        });
        return workOrder;
      } catch (error) {
        if (error instanceof ApolloError) {
          const conflictError = error.graphQLErrors.find(
            (error) => error.extensions?.code === '409'
          );
          if (conflictError) {
            toast({
              title: conflictError.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            return;
          }
        }
        throw error;
      } finally {
        loading(false);
      }
    },
    [toast, addWorkOrderMutation, t_toasts, loading]
  );

  const updateWorkOrder = useCallback(
    async (payload: UpdateWorkOrderFormDataType) => {
      loading(true);
      try {
        const { data } = await updateWorkOrderMutation({
          variables: { updateWorkOrderInput: payload as UpdateWorkOrderInput },
        });
        if (!data) throw new Error('Data Error');
        const workOrder = data.updateWorkOrder as IWorkOrder;
        toast({
          title: t_toasts('success.task.task-updated'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        return workOrder;
      } catch (error) {
        if (error instanceof ApolloError) {
          const conflictError = error.graphQLErrors.find(
            (error) => error.extensions?.code === '409'
          );
          if (conflictError) {
            toast({
              title: conflictError.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            return;
          }
        }
        throw error;
      } finally {
        loading(false);
      }
    },
    [updateWorkOrderMutation, toast, t_toasts, loading]
  );

  return {
    addWorkOrder,
    updateWorkOrder,
  };
};
